<template>
  <div class="head_top">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/technology' }">技术支持</el-breadcrumb-item>
      <el-breadcrumb-item>网络摄像机(室内)</el-breadcrumb-item>
      <el-breadcrumb-item>C48Q</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="flex">
      <div class="font_center">C48Q</div>
      <div><img src="../../../../public/images/二级页面/WiFi机/CS48Q.jpg"></div>
    </div>
    <!--    tab-->
    <div>
      <el-tabs type="border-card">
        <el-tab-pane label="常见问题">
          <el-collapse v-model="activeName" accordion>
            <el-collapse-item title="一致性 Consistency" name="1">
              <div>与现实生活一致：与现实生活的流程、逻辑保持一致，遵循用户习惯的语言和概念；</div>
              <div>在界面中一致：所有的元素和结构需保持一致，比如：设计样式、图标和文本、元素的位置等。</div>
            </el-collapse-item>
            <el-collapse-item title="反馈 Feedback" name="2">
              <div>控制反馈：通过界面样式和交互动效让用户可以清晰的感知自己的操作；</div>
              <div>页面反馈：操作后，通过页面元素的变化清晰地展现当前状态。</div>
            </el-collapse-item>
            <el-collapse-item title="效率 Efficiency" name="3">
              <div>简化流程：设计简洁直观的操作流程；</div>
              <div>清晰明确：语言表达清晰且表意明确，让用户快速理解进而作出决策；</div>
              <div>帮助用户识别：界面简单直白，让用户快速识别而非回忆，减少用户记忆负担。</div>
            </el-collapse-item>
            <el-collapse-item title="可控 Controllability" name="4">
              <div>用户决策：根据场景可给予用户操作建议或安全提示，但不能代替用户进行决策；</div>
              <div>结果可控：用户可以自由的进行操作，包括撤销、回退和终止当前操作等。</div>
            </el-collapse-item>
          </el-collapse>
        </el-tab-pane>
        <el-tab-pane label="视频教程">
          <div class="flex">
            <div class="c48q_box"></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="说明书"></el-tab-pane>
        <el-tab-pane label="规格表"></el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
export default {
  name: "C48Q",
  data() {
    return {
      activeName: '1'
    };
  }
}
</script>

<style scoped>
.el-breadcrumb {
  font-size: 16px;
  margin-left: 150px;
}

.el-tabs--border-card {
  margin: auto;
  width: 80%;
}

.flex {
  display: flex;
  justify-content: space-evenly;
}

.font_center {
  font-size: 36px;
  line-height: 400px;
}
.c48q_box{

}
</style>